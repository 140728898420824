$tablet-width: 768px;
$tablet-old-width: 480px;
$desktop-width: 980px;
$mobile-width: 320px;
$main-width: 1000px;
@mixin mainwidth {
	@media (min-width: #{$main-width}) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin tablet-small {
	@media (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (max-width: #{$main-width - 1px}) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: #{$tablet-old-width - 1px}) {
		@content;
	}
}

@mixin mobile-small {
	@media (max-width: #{$mobile-width - 1px}) {
		@content;
	}
}
