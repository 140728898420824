
body {
	font: normal $baseFontSize $baseFont;
	color: #333;
	background: url(../img/background/body_bg.gif) left top repeat-x;
	background-color: #f0f0f0;
}

a {
	text-decoration: underline;
	color: #333;
	transition: color .4s ease;
	&:hover {
		text-decoration: none;
	}
}

//typo
h1, h2, h3, h4 {
	line-height: normal;
}

h1 {
	font-size: 28px;
	font: normal $baseFont;
}

h2 {
	font-size: 24px;
}

h3 {
	font-size: 22px;
}

h4 {
	font-size: 18px;
}

.single-post-content {

	h1 {
		padding-bottom: 20px;
	}

	h2 {
		padding-bottom: 10px;
	}

	h3 {
		padding-bottom: 10px;
	}
	h4 {
		padding-bottom: 10px;
	}
	img {
		max-width: 100%;
		height: auto;
	}

}

//typo





