html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, fieldset, form, label, legend,
table, caption, tbody, tfoot, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

/* @end */

input[type=search], input[type=number] {
  /* the webkit overrides need to stay at the top */
  -webkit-appearance: textfield;
  outline: none;
  /* your styles here */
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

a {
  outline: none;
}

.element-invisible {
  display: none;
}

.element-invisible {
  display: none;
}

input[type="text"], input[type="password"], textarea, select, button {
  outline: none;
  -webkit-appearance: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:focus {
  outline-color: transparent;
  outline-style: none;
  outline: none;
}

*::-webkit-input-placeholder {
  opacity: 1;
}

*:-moz-placeholder {
  opacity: 1;
}

*::-moz-placeholder {
  opacity: 1;
}

*:-ms-input-placeholder {
  opacity: 1;
}

*:focus::-webkit-input-placeholder {
  opacity: 0;
}

*:focus:-moz-placeholder {
  opacity: 0;
}

*:focus::-moz-placeholder {
  opacity: 0;
}

*:focus:-ms-input-placeholder {
  opacity: 0;
}

strong, b {
  font-weight: bold;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  height: 100%;
}

ul li.leaf, ul li.expanded, .item-list ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.form-item, .form-actions {
  margin: 0;
}