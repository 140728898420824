//layout
//layout
.center {
	@include center;
	@include desktop {
		width: auto;
	}
}
.page-container {
	width: 100%;
	overflow-x: hidden;
	@include flex(flex, column, nowrap, space-between, stretch, flex-start);
	@include ie {
		height: 100vh;
	}
	min-height: 100%;
	.content-container {
		flex: 1 0 auto;
		width: 100%;
		min-width: 0;
		padding-top: 27px;
	}
}

i {font-style: italic!important;}

/*header*/
.top-line {
	background: #383838;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	padding: 4px 40px 3px 0px;
	height: 27px;
	color: #fff;
	z-index: 999;
	a {
		color: #fff;
	}
	&_basket {
		text-align: right;
		a {
			&:before {
				content: '';
				@include icon_basket_red;
				display: inline-block;
				vertical-align: middle;
				position: relative;
				top: -2px;
				margin-right: 5px;
			}
		}
	}
}
header {
	@include flex(flex, row, nowrap, space-between, flex-start, flex-start);
	background: url(../img/background/header.jpg) center top no-repeat;
	height: 81px;


	.logo {
		order: 0;
		flex: 0 1 auto;
		align-self: auto;
		background: url(../img/logos/logo.png) left top no-repeat;
		font-family: 'roboto-regular';
		width: 164px;
		padding-top: 32px;
		text-align: right;
		font-size: 10px;
		text-transform: uppercase;
		text-decoration: none;
		margin: 22px 0px 0px 27px;
		overflow: inherit!important;
		float: none!important;

		@include tablet-small {
			flex: 1 1 auto;
			text-align: center;
			background-position: center top;
		}

	}
	.header-title {
		order: 0;
		flex: 0 1 auto;
		align-self: auto;
		padding-top: 22px;
		color: #000;
		font-family: 'roboto-regular';
		text-transform: uppercase;
		font-size: 12px;
		margin-left: 70px;
		width: 350px;
		@include desktop {
			display: none;
		}
		strong {
			font-family: 'roboto-black';
		}

		span {
			display: inline-block;
			vertical-align: top;
			margin-right: 22px;
		}
		p {
			vertical-align: top;
			display: inline-block;
		}
	}
	.header-contacts {
		order: 0;
		flex: 0 1 auto;
		align-self: auto;
		font-family: 'roboto-light';
		font-size: 12px;
		color: #000;
		margin-top: 22px;


		@include tablet-small {
			display: none;
		}

		&_phone {
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
			display: inline-block;
			&:first-child {
				&:after {
					content: '';
					background: #bdbdbd;
					height: 52px;
					position: absolute;
					right: 0px;
					top: -7px;
					width: 1px;

				}
			}
			p span {
				font-family: 'roboto-regular';
				font-size: 23px;
				span {
					font-size: 16px; color: #139760;
				}
			}
		}
	}
	.icon-nav {
		@include icon-nav;
		display: none;
		@include desktop {
			order: 0;
			flex: 0 1 auto;
			align-self: auto;

			flex-shrink: 0;
			margin-top: 30px;

			display: block;
			width: 29px;
			height: 20px;
			background-size: cover;
			margin-left: 20px;

		}
	}
}
/*header*/

/*nav*/
@media screen and (min-width: 1000px) {
	nav { height: 36px !important; width: 100% !important;}
}
@include desktop {
	nav.open {display: block;}
}
nav {
	background: #F9F9F9;
	background: -webkit-gradient(linear, 0 0, 0 bottom, from(#F9F9F9), to(#e9e9e9));
	background: -webkit-linear-gradient(#F9F9F9, #e9e9e9);
	background: -moz-linear-gradient(#F9F9F9, #e9e9e9);
	background: -ms-linear-gradient(#F9F9F9, #e9e9e9);
	background: -o-linear-gradient(#F9F9F9, #e9e9e9);
	background: linear-gradient(#F9F9F9, #e9e9e9);
	-pie-background: linear-gradient(#F9F9F9, #e9e9e9);
	height: 36px;
	border-top: 1px solid #bdbdbd;
	border-bottom: 1px solid #bdbdbd;

	@include desktop {
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
		display: none;
		left: 0;
		top: 27px;
		position: absolute;
	}

	ul {
		@include flex(flex, row, nowrap, flex-start, flex-start, flex-start);
		@include desktop {
			@include flex(flex, column, wrap, flex-start, flex-start, flex-start);
		}
		list-style: none;
		padding: 0px;
		margin: 0px;
		li {
			list-style: none;
			padding: 0px;
			order: 0;
			flex: 0 1 auto;
			align-self: auto;
			height: 36px;

			@include desktop {
				flex: 1 1 auto;
				margin-left: 10px;
				height: auto;
				.drop {
					display: none;
					a {
						padding: 4px 14px 5px 14px;
						height: auto;
					}
				}
			}
			@include mainwidth {
				.drop {
					background: #fff;
					position: absolute;
					z-index: 999;
					display: none;
					ul {
						@include flex(flex, column, nowrap, space-between, flex-start, flex-start);
						li {flex: 1 1 auto;
							width: 100%;
							height: auto;
							border-bottom: 1px solid #f2f2f2;
							a {
								display: block;
								height: auto;
								padding: 6px 14px 7px 14px;
								&:before { display: none;}
							}
						}
					}
				}
				&:hover .drop { display: block;}
			}



			a {
				display: inline-block;
				height: 36px;
				padding: 9px 14px 10px 14px;
				text-decoration: none;
				color: #0f5819;
				font-family: 'opensans-semibold';
				position: relative;
				line-height: 16px;
				&:before {
					content: '';
					@include icon_nav_li_a_before;
					background-repeat: repeat-y;
					position: absolute;
					right: 0px;
					top: 0px;
					bottom: 0px;
					height: auto;
					@include  desktop {
						display: none;;
					}
				}
				&:hover {color: #45c791;}
			}
			&:last-child a:before {display: none;}
		}
	}

}
/*nav*/

/*section*/
section {
	background: #fff;
	padding: 20px;
}
.offers {
	margin-bottom: 20px;

	@include desktop {
		margin-bottom: 10px;
	}

	ul {
		@include flex(flex, row, nowrap, space-between, flex-start, flex-start);
		@include desktop {
			@include flex(flex, row, wrap, space-between, flex-start, flex-start);
		}
		list-style: none;
		padding: 0px;
		margin: 0px;
		li {
			list-style: none;
			padding: 0px;
			order: 0;
			flex: 0 1 auto;
			align-self: auto;

			@include desktop {
				flex: 0 1 auto;
				margin-bottom: 10px;
				width: 49%;
				display: table;
			}

			@include tablet-small {
				width: 100%;
			}

			a {
				background: #15ac6d;
				font-family: 'opensans-bold';
				font-size: 18px;
				display: table-cell;
				height: 96px;
				vertical-align: middle;
				width: 234px;
				text-transform: uppercase;
				color: #fff;
				text-decoration: none;
				padding-left: 11px;
				position: relative;

				@include desktop {
					width: 100%;
				}
				@include tablet-small {
					height: 50px;
					font-size: 12px;
					br {
						display: none;;
					}
				}

				&:hover {
					background: #17b975;
				}

				&:before {
					content: '';
					@include icon_offers_arrow;
					position: absolute;
					right: 10px;
					top: 26px;
					@include tablet-small {
						top: 2px;
					}
				}
			}
		}
	}

}

.main-slider {
	ul {padding: 0px; list-style: none; margin: 0px;}
	li {
		padding: 0px; list-style: none; margin: 0px;
		font-size: 0px;

	}
	&_desc {
		position: absolute;
		background: rgb( 84, 91, 106);
		background: rgba( 84, 91, 106, 0.75);
		left: 0;
		right: 0;
		bottom: 0;
		font-size: 26px;
		color: #fff;
		padding: 25px;
		text-align: center;

		@include tablet-small {
			font-size: 12px;
			padding: 10px;
		}

		&_title {
			font-family: 'roboto-black';
			font-size: 30px;
			@include tablet-small {font-size: 16px;}
		}
		span {
			display: block;
		}
	}
	img { width: 100%;}
}

.news {
	border: 1px solid #e7e7e7;
	margin-left: 0px!important;
	margin-bottom: 20px;
	@include tablet-small { font-size: 11px;}
	ul {
		@include flex(flex, row, nowrap, space-between, flex-start, flex-start);
		@include mobile {
			@include flex(flex, column, nowrap, space-between, flex-start, flex-start);
		}
		list-style: none;
		padding: 0px;
		margin: 0px;
		li {
			list-style: none;
			padding: 0px;
			order: 0;
			flex: 1 1 auto;
			align-self: stretch;
			width: 33.333%;
			flex-shrink: 0;
			text-decoration: none;
			border-right: 1px solid #e7e7e7;
			position: relative;
			@include mobile {
				width: 100%;
			}

			div {
				padding: 31px 12px 18px 12px;
				display: block;
				height: 100%;
				text-decoration: none;
				a { text-decoration: none;}
			}
			.icon {
				content: '';
				@include icon-bx-pager;
				position: absolute;
				right: 10px;
				top: 10px;
				z-index: 55;
				cursor: pointer;
				@include mobile { display: none;}
			}
			.icon:hover {
				@include icon-bx-pager-green;
			}
			&:hover {
				background: #f2f2f2;
			}
			.active {
				background: #f2f2f2;
				@include mobile {
					background: #0f5819;
				}
			}


			&:last-child {
				border: none;
			}

		}
	}
	&_date {
		color: #666666;
		padding-bottom: 7px;
	}
}

.technologies {
	margin-bottom: -10px;
	ul {
		@include flex(flex, row, nowrap, space-between, flex-start, flex-start);

		@include desktop {
			@include flex(flex, row, wrap, center, flex-start, flex-start);
		}
		list-style: none;
		padding: 0px;
		margin: 0px;
		li {
			list-style: none;
			padding: 0px;
			order: 0;
			flex: 0 1 auto;
			align-self: stretch;
			width: 183px;
			flex-shrink: 0;

			@include desktop {
				width: 33.333%;
				margin-bottom: 10px;
				&:nth-last-child(1) { width: 50%;}
				&:nth-last-child(2) { width: 50%;}
			}

			@include tablet-small {
				width: 50%;
				&:nth-last-child(1) { width: 100%;}
			}

			@include mobile {
				width: 100%;
				&:nth-last-child(1) { width: 100%;}
				&:nth-last-child(2) { width: 100%;}
			}

			a {
				background: #f2f2f2;
				display: block;
				height: 100%;
				padding: 30px 10px 15px 15px;
				text-decoration: none;
				position: relative;
				color: #1a1a1a;

				@include desktop {
					margin-right: 5px;
					margin-left: 5px;
				}

				&:hover {
					background: #fff;
				}

				&:before {
					content: '';
					position: absolute;
					left: 0px;
					right: 0px;
					top: 0;
					height: 15px;
				}

				span {
					display: block;
				}
			}
			&:nth-child(1) a:before {background: #c6a03e;}
			&:nth-child(2) a:before {background: #539faa;}
			&:nth-child(3) a:before {background: #6186b7;}
			&:nth-child(4) a:before {background: #b76183;}
			&:nth-child(5) a:before {background: #d3363a;}

			&:nth-child(1) a:hover {border: 1px solid #c6a03e; border-top: none; padding: 30px 9px 14px 14px;}
			&:nth-child(2) a:hover {border: 1px solid #539faa; border-top: none; padding: 30px 9px 14px 14px;}
			&:nth-child(3) a:hover {border: 1px solid #6186b7; border-top: none; padding: 30px 9px 14px 14px;}
			&:nth-child(4) a:hover {border: 1px solid #b76183; border-top: none; padding: 30px 9px 14px 14px;}
			&:nth-child(5) a:hover {border: 1px solid #d3363a; border-top: none; padding: 30px 9px 14px 14px;}
		}
	}
	&_title {
		font-family: 'opensans-bold';
		font-size: 18px;
		text-transform: uppercase;
		padding-bottom: 7px;
	}
}
/*section*/

/*footer*/
footer {
	background: #fff;
	padding: 10px 30px 15px 30px;

	&:before {
		content: '';
		height: 1px;
		background: #dcdcdc;
		display: block;
		margin-bottom: 18px;
	}
}
.search {
	@include flex(flex, row, nowrap, flex-start, flex-start, flex-start);
	margin-bottom: 20px;

	@include desktop {
		@include flex(flex, row, nowrap, space-between, flex-start, flex-start);
	}

	input[type=search] {
		order: 0;
		flex: 0 1 auto;
		align-self: stretch;
		border: 1px solid #dcdcdc;
		width: 242px;
		padding: 9px;
		color: #b2b2b2;

		@include desktop {
			flex: 1 1 auto;
		}
	}
	button {
		order: 0;
		flex: 0 1 auto;
		align-self: stretch;
		background: #4e7fd5;
		border: none;
		vertical-align: middle;
		width: 72px;
		margin-left: 15px;
		border-radius: 3px;
		cursor: pointer;
		font-family: $baseFont;
		&:before {
			content: '';
			@include icon-search;
			display: inline-block;
			position: relative;
			top: 2px;
		}
	}
}
.footer-information {
	@include flex(flex, row, nowrap, space-between, flex-start, flex-start);

	@include desktop {
		@include flex(flex, column, wrap, space-between, flex-start, flex-start);
	}

	&_copy {
		order: 0;
		flex: 0 1 auto;
		align-self: auto;
		font-size: 11px;

		@include desktop {
			flex: 1 1 auto;
			align-self: center;
		}
		@include tablet-small {
			width: 100%;
		}

		strong {
			font-family: 'opensans-semibold';
		}
		p:last-child { padding-top: 10px;}
		p span {font-size: 9px;}
		a {color: #14a267;}
	}
	&_logos {
		order: 0;
		flex: 0 1 auto;
		align-self: auto;
		@include desktop {
			align-self: center;
		}

		@include tablet-small {
			display: none;
		}

		a {
			margin-left: 20px;
		}
	}
}
/*footer*/